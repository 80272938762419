import { SliderSnippet } from '@netfront/ekardo-content-library';

import { SliderProps } from './Slider.interfaces';

import { useIsMounted } from '../../hooks';

export const Slider = ({ onMoveStart, sliderData }: SliderProps) => {
  const { isMounted } = useIsMounted();

  return isMounted ? (
    <div className="c-home-quote-slider">
      <SliderSnippet slider={sliderData} onMoveStart={onMoveStart} />
    </div>
  ) : null;
};
