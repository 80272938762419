import { IDirectoryFormFields } from '../DirectoryForm';

const DIRECTORY_FORM_BLOCK_CSS_IDENTIFIERS = Object.freeze({
  form: 'c-directory-form',
});

const DIRECTORY_FORM_FIELDS = Object.freeze({
  contactEmail: Object.freeze({
    id: 'contact-email',
    label: 'Contact email',
  }),
  contactNumber: Object.freeze({
    id: 'contact-number',
    label: 'Contact number',
  }),
  postcode: Object.freeze({
    id: 'postcode',
    label: 'Postcode',
  }),
  serviceDescription: Object.freeze({
    id: 'service-description',
    label: 'Service description',
  }),
  siteOrganisationName: Object.freeze({
    id: 'site-organisation-name',
    label: 'Site/Organisation name',
  }),
  state: Object.freeze({
    id: 'state',
    label: 'State',
  }),
  street: Object.freeze({
    id: 'street',
    label: 'Street',
  }),
  suburb: Object.freeze({
    id: 'suburb',
    label: 'Suburb',
  }),
  websiteUrl: Object.freeze({
    id: 'website-url',
    label: 'Website URL',
  }),
});

const DIRECTORY_FORM_INITIAL_VALUES: IDirectoryFormFields = {
  contactEmail: '',
  contactNumber: '',
  serviceDescription: '',
  siteOrganisationName: '',
  postcode: '',
  state: '',
  street: '',
  suburb: '',
  websiteUrl: '',
};

export { DIRECTORY_FORM_BLOCK_CSS_IDENTIFIERS, DIRECTORY_FORM_FIELDS, DIRECTORY_FORM_INITIAL_VALUES };
