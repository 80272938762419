import { useEffect } from 'react';

import { ContentPageProvider } from '@netfront/ekardo-content-library';
import { useUser } from '@netfront/gelada-identity-library';
import { Icons } from '@netfront/ui-library';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { Toaster } from 'react-hot-toast';

import { PageView } from '../components';

import '@egjs/flicking-plugins/dist/arrow.min.css';
import '@egjs/flicking-plugins/dist/pagination.min.css';
import '@egjs/react-flicking/dist/flicking.css';
import '@netfront/ekardo-content-library/dist/index.css';
import '@netfront/ui-library/dist/index.css';
import 'react-circular-progressbar/dist/styles.css';
import '../styles/cms.css';
import '../styles/globals.css';
import '../styles/theme.css';
import '../styles/utils.css';

function App({ Component, pageProps }: AppProps) {
  const router = useRouter();

  const { id: userId } = useUser();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      PageView(url, String(userId));
    };
    // When the component is mounted, subscribe to router changes
    // and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events, userId]);

  return (
    <div id="root">
      <div>
        <Toaster position="top-center" reverseOrder={false} />
      </div>
      <Icons />
      <ContentPageProvider>
        {
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Component {...pageProps} />
        }
      </ContentPageProvider>
    </div>
  );
}

// eslint-disable-next-line import/no-default-export
export default App;
