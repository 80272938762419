const INVITE_COLLEAGUE_PAGE_TITLE = 'Invite a colleague page';
const INVITE_COLLEAGUE_SEO_DESCRIPTION = 'Invite a colleague to CTLS';
const INVITE_COLLEAGUE_SEO_TITLE = 'Invite a colleague';
const INVITE_COLLEAGUE_TEMPLATE_ID = 142;

const inviteAColleaguePageConstants = Object.freeze({
  INVITE_COLLEAGUE_SEO_DESCRIPTION: INVITE_COLLEAGUE_SEO_DESCRIPTION,
  INVITE_COLLEAGUE_SEO_TITLE: INVITE_COLLEAGUE_SEO_TITLE,
  INVITE_COLLEAGUE_PAGE_TITLE: INVITE_COLLEAGUE_PAGE_TITLE,
  INVITE_COLLEAGUE_TEMPLATE_ID: INVITE_COLLEAGUE_TEMPLATE_ID,
});

export { inviteAColleaguePageConstants };
