import { MobileMenuTriggerProps } from './MobileMenuTrigger.interfaces';

import { Button } from '../../Button';
import { CrossIcon, MenuIcon } from '../../Icons';

const MobileMenuTrigger = ({ isOpen, onPress }: MobileMenuTriggerProps) => {
  return (
    <Button
      aria-expanded={isOpen}
      className="items-center bg-transparent border-none flex justify-center ml-auto pointer xl:none p-0"
      aria-haspopup
      onPress={onPress}
    >
      <span className="sr-only">{`${isOpen ? 'Close' : 'Open'} side navigation`}</span>
      {isOpen ? <CrossIcon /> : <MenuIcon />}
    </Button>
  );
};

export { MobileMenuTrigger };
