import { gql } from '@apollo/client';

const UPDATE_USER_MUTATION = gql`
  mutation updateUserAsAdmin(
    $userId: Int!
    $firstName: String = null
    $lastName: String = null
    $email: String = null
    $password: String = null
  ) {
    user {
      updateUserAsAdmin(userId: $userId, email: $email, firstname: $firstName, lastname: $lastName, password: $password)
    }
  }
`;

export { UPDATE_USER_MUTATION };
