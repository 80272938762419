import { useEffect } from 'react';

import { CMSContentPage, useContentPageContext } from '@netfront/ekardo-content-library';

import { Page } from '../Page';

import { PublicCMSPageProps } from './PublicCMSPage.interfaces';

const PublicCMSPage = ({ contentPage }: PublicCMSPageProps) => {
  const { dispatch } = useContentPageContext();

  useEffect(() => {
    dispatch({
      payload: {
        contentPage,
      },
      type: 'loadContentPage',
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentPage]);

  return (
    <Page title="">
      <CMSContentPage />
    </Page>
  );
};

export { PublicCMSPage };
