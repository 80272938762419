import React from 'react';

import cx from 'classnames';

import { PartnerHubCardProps } from './PartnerHubCard.interfaces';
import styles from './PartnerHubCard.module.css';

const PartnerHubCard: React.FC<PartnerHubCardProps> = ({
  additionalClassNames,
  coverLink,
  image: { altText, src },
  isPageTitle = false,
  subTitle,
  title,
}) => {
  const pagePreviewCardClassNames = cx('c-partner-hub-card', additionalClassNames);

  const containerClassNames = cx('c-partner-hub-card__container');
  const TitleTag = isPageTitle ? 'h1' : 'span';

  return (
    <article className={styles[containerClassNames]}>
      <div className={styles[pagePreviewCardClassNames]}>
        <div className={styles['c-partner-hub-card__image-container']}>
          <img alt={altText} className={styles['c-partner-hub-card__image']} src={src} />
        </div>
        <span className={styles['c-partner-hub-card__sub-title']}>{subTitle}</span>
        <TitleTag className={styles['c-partner-hub-card__title']}>{title}</TitleTag>
        {coverLink}
      </div>
    </article>
  );
};

export { PartnerHubCard };
