import { IMapCoordinates } from '@netfront/common-library';

const DIRECTORY_TYPE_IDS = Object.freeze({
  getHelp: 5,
  resources: 2,
  services: 3,
});

const SYDNEY_LOCATION: IMapCoordinates = {
  latitude: -33.865143,
  longitude: 151.2099,
};

export { DIRECTORY_TYPE_IDS, SYDNEY_LOCATION };
