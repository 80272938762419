import React from 'react';

import cx from 'classnames';

import { BreadcrumbItemLinkProps } from './BreadcrumbItemLink.interfaces';

import { Link } from '../../Link';

const BreadcrumbItemLink = ({ content, href }: BreadcrumbItemLinkProps) => {
  return (
    <Link className={cx('color-black flex')} href={href}>
      {content}
    </Link>
  );
};

export { BreadcrumbItemLink };
