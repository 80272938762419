const LOGO_SIZES = Object.freeze({
  small: '10',
  medium: '20',
  large: '34',
});

const logoLinkConstants = Object.freeze({
  LOGO_SIZES: LOGO_SIZES,
});

export { logoLinkConstants };
