import { yupResolver } from '@hookform/resolvers/yup';
import { getBEMClassName, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, Input, Textarea } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { CONTACT_FORM_BLOCK_CSS_IDENTIFIERS, CONTACT_FORM_INITIAL_VALUES } from './ContactForm.constants';
import { IContactForm, IContactFormFields } from './ContactForm.interfaces';

const ContactForm = ({ additionalSubmitButtonClassNames, buttonText = 'Send', isSubmitting, onSend }: IContactForm) => {
  const { form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    email: { id: emailId, label: emailLabel, placeholder: emailPlaceholder, validationErrorMessage: emailValidationErrorMessage },
    firstName: { id: firstNameId, label: firstNameLabel, placeholder: firstNamePlaceholder },
    lastName: { id: lastNameId, label: lastNameLabel, placeholder: lastNamePlaceholder },
    message: { id: messageId, label: messageLabel },
  } = FORM_FIELDS;

  const { form: formBlockCssId } = CONTACT_FORM_BLOCK_CSS_IDENTIFIERS;

  const { control, handleSubmit } = useForm<IContactFormFields>({
    defaultValues: CONTACT_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    resolver: yupResolver(
      yup.object().shape({
        email: yup.string().label(emailLabel).email(emailValidationErrorMessage).required(),
        firstName: yup.string().label(firstNameLabel).required(),
        lastName: yup.string().label(lastNameLabel).required(),
        message: yup.string().label(messageLabel).required(),
      }),
    ),
    reValidateMode: 'onChange',
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <>
      <form
        className={getBEMClassName(formBlockCssId, formElementCssId)}
        onSubmit={handleSubmit(({ email, firstName, lastName, message }: IContactFormFields) => {
          onSend({
            email,
            firstName,
            lastName,
            message,
          });
        })}
      >
        <div className="c-form__header" />
        <div className="c-form__body">
          <div className="flex flex-col md:flex-row -mx-2">
            <div className="c-form__field w-1/2 px-2 py-4 w-full">
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={getBEMClassName(formBlockCssId, firstNameId)}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${firstNameId}-contact`}
                    isDisabled={isSubmitting}
                    labelText={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    type="text"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="c-form__field w-1/2 px-2 py-4 w-full">
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={getBEMClassName(formBlockCssId, lastNameId)}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${lastNameId}-contact`}
                    isDisabled={isSubmitting}
                    labelText={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    type="text"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex -mx-2">
            <div className="c-form__field w-1/2 px-2 py-2 w-full">
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={getBEMClassName(formBlockCssId, emailId)}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${emailId}-contact`}
                    isDisabled={isSubmitting}
                    labelText={emailLabel}
                    placeholder={emailPlaceholder}
                    type="email"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="c-form__field px-2 py-4 w-full">
            <Controller
              control={control}
              name="message"
              render={({ field, fieldState }) => (
                <Textarea
                  additionalClassNames={getBEMClassName(formBlockCssId, messageId)}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id={`${messageId}-contact`}
                  isDisabled={isSubmitting}
                  labelText={messageLabel}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="c-form__footer">
          <Button additionalClassNames={additionalSubmitButtonClassNames} isDisabled={isSubmitting} text={buttonText} type="submit" />
        </div>
      </form>
    </>
  );
};

export { ContactForm };
