const CONTACT_ICON_STYLES = 'max-w-6 max-h-6 mr-2';
const CONTACT_PAGE_TITLE = 'Contact us';
const CONTACT_SECTION_STYLES = 'flex color-white mb-5 size-5 ek-cope-result__body';

const CONTACT_ADDRESS_ICON = '/images/ctls/address-icon.svg';
const CONTACT_EMAIL_ICON = '/images/ctls/email-icon.svg';
const CONTACT_PHONE_ICON = '/images/ctls/phone-icon.svg';

const contactPageConstants = Object.freeze({
  CONTACT_ADDRESS_ICON: CONTACT_ADDRESS_ICON,
  CONTACT_EMAIL_ICON: CONTACT_EMAIL_ICON,
  CONTACT_ICON_STYLES: CONTACT_ICON_STYLES,
  CONTACT_PAGE_TITLE: CONTACT_PAGE_TITLE,
  CONTACT_PHONE_ICON: CONTACT_PHONE_ICON,
  CONTACT_SECTION_STYLES: CONTACT_SECTION_STYLES,
});

export { contactPageConstants };
