import { ButtonClasses, ButtonSize, ButtonTheme } from './Button.types';

const BASE_STYLES = 'items-center font-header inline-flex justify-center text-center pointer px-6 rounded-full weight-700';

const BUTTON_FOCUS_CLASSES: Record<ButtonTheme, string> = Object.freeze({
  default: 'focus:bg-yellow-500 focus:color-white',
  redOutline: 'focus:bg-primary focus:border-white focus:color-white focus:ring-primary',
  yellow: '',
});

const BUTTON_SIZE_CLASSES: Record<ButtonSize, string> = Object.freeze({
  auto: 'h-auto min-w-auto',
  default: 'h-14 min-w-40',
  lg: '',
  md: '',
  sm: '',
  xl: '',
});

const BUTTON_CLASSES: ButtonClasses = Object.freeze({
  default: `${BASE_STYLES} bg-primary color-white hover:bg-yellow-500 hover:color-white ${BUTTON_SIZE_CLASSES.default} ${BUTTON_FOCUS_CLASSES.default}`,
  redOutline: `${BASE_STYLES} bg-white color-red-700 border-2 border-red-700  hover:bg-red-700 hover:color-white ${BUTTON_SIZE_CLASSES.default} ${BUTTON_FOCUS_CLASSES.redOutline}`,
  yellow: `${BASE_STYLES} bg-green-900 border-2 border-green-900 color-white ${BUTTON_SIZE_CLASSES.default}`,
});

export { BASE_STYLES, BUTTON_CLASSES, BUTTON_FOCUS_CLASSES, BUTTON_SIZE_CLASSES };
