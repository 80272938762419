import { IRootMenuItem } from './Header.interfaces';

const MENU_ITEMS: IRootMenuItem[] = [
  {
    id: 1,
    href: '/',
    label: 'Home',
  },
  {
    id: 2,
    href: '/public-page/capabilities',
    label: 'Capability',
  },
  {
    id: 3,
    href: '/public-page/sustainability',
    label: 'Sustainability',
  },
  {
    id: 4,
    href: '/public-page/about-us',
    label: 'About us',
  },
  {
    id: 5,
    href: '/contact-us',
    label: 'Contact',
  },
];

export { MENU_ITEMS };
