import { useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IResetPasswordOnCompletedResponse, useResetPassword, ResetPasswordForm } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import { BUTTON_CLASSES, Link, SingleFormPage } from '../..';
import { useToast } from '../../../hooks';

const PAGE_TITLE = 'Reset password';

const ResetPasswordPage = () => {
  const { query } = useRouter();
  const { code } = query;

  const [isResetPasswordCompleted, setIsResetPasswordCompleted] = useState<boolean>(false);

  const { handleToastError } = useToast();

  const handleResetPasswordCompleted = ({ isCompleted }: IResetPasswordOnCompletedResponse) => {
    setIsResetPasswordCompleted(isCompleted);
  };

  const handleResetPasswordError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleResetPassword, isLoading = false } = useResetPassword({
    onCompleted: handleResetPasswordCompleted,
    onError: handleResetPasswordError,
  });

  return (
    <SingleFormPage
      isPreloaderVisible={isLoading}
      meta={{ seoDescription: 'Receive a reset link', seoTitle: PAGE_TITLE }}
      title={PAGE_TITLE}
    >
      <h1 className="color-accent h4 mb-2">{PAGE_TITLE}</h1>
      <h2 className="mb-10">Change your password</h2>

      {!isResetPasswordCompleted ? (
        <ResetPasswordForm
          buttonClassName={BUTTON_CLASSES.default}
          isSubmitting={isLoading}
          onResetPassword={(password) =>
            handleResetPassword({
              code: String(code),
              newPassword: password,
            })
          }
        />
      ) : (
        <>
          <div className="mb-8 md:mb-8">Your password has been successfully reset.</div>

          <Link href="/login" theme="default">
            Return to login
          </Link>
        </>
      )}
    </SingleFormPage>
  );
};

export { ResetPasswordPage };
