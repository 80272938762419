import { useState } from 'react';

import { ApolloError } from '@apollo/client';
import { IForgotPasswordOnCompletedResponse, ForgotPasswordForm, useForgotPassword } from '@netfront/gelada-identity-library';

import { BUTTON_CLASSES, Link, SingleFormPage } from '../../../components';
import { useToast } from '../../../hooks';

const PAGE_TITLE = 'Forgot password';

const ForgotPasswordPage = () => {
  const [isForgotPasswordCompleted, setIsForgotPasswordCompleted] = useState<boolean>(false);

  const { handleToastError } = useToast();

  const handleForgotPasswordCompleted = ({ isCompleted }: IForgotPasswordOnCompletedResponse) => {
    setIsForgotPasswordCompleted(isCompleted);
  };

  const handleForgotPasswordError = (error: ApolloError) => {
    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleForgotPassword, isLoading = false } = useForgotPassword({
    onCompleted: handleForgotPasswordCompleted,
    onError: handleForgotPasswordError,
  });

  return (
    <SingleFormPage
      isPreloaderVisible={isLoading}
      meta={{ seoDescription: 'Receive a reset link', seoTitle: PAGE_TITLE }}
      title={PAGE_TITLE}
    >
      <div className="py-40">
        <h1 className="h1 color-red-700 mb-2">{PAGE_TITLE}</h1>
        <span className="mb-10 font-size-p">Access your account by entering your details below</span>
        {!isForgotPasswordCompleted ? (
          <ForgotPasswordForm
            buttonClassName={BUTTON_CLASSES.default}
            buttonText="Submit"
            isSubmitting={isLoading}
            onForgotPassword={(email) =>
              handleForgotPassword({
                email,
              })
            }
          />
        ) : (
          <>
            <div className="mb-8 md:mb-8">An email has been sent with a reset password link to your nominated email.</div>

            <div className="mb-8 md:mb-8">
              To finalise your password reset, click the link within the email and follow the prompts to complete the reset password
              process.
            </div>

            <Link href="/login" theme="default">
              Return to login
            </Link>
          </>
        )}
      </div>
    </SingleFormPage>
  );
};

export { ForgotPasswordPage };
