import { useLoggedGeladaMutation } from '@netfront/gelada-identity-library';

import { UPDATE_USER_MUTATION } from './useUpdateUser.graphql';
import {
  IHandleUpdateUserParams,
  IUpdateUserMutationGraphQLResponse,
  IUpdateUserMutationVariables,
  IUseUpdateUserOptions,
  IUseUpdateUser,
} from './useUpdateUser.interfaces';

const useUpdateUser = (options?: IUseUpdateUserOptions): IUseUpdateUser => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseUpdateUserOptions);

  const [executeUpdateUser, { loading: isLoading }] = useLoggedGeladaMutation<
    IUpdateUserMutationGraphQLResponse,
    IUpdateUserMutationVariables
  >({
    mutation: mutation ?? UPDATE_USER_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          user: { updateUserAsAdmin: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleUpdateUser = async ({ email, firstName, lastName, password, userId }: IHandleUpdateUserParams) => {
    await executeUpdateUser({
      variables: {
        email,
        firstName,
        lastName,
        password,
        userId,
      },
    });
  };

  return {
    handleUpdateUser,
    isLoading,
  };
};

export { useUpdateUser };
