import { useState } from 'react';

import { usePreventScroll } from '@react-aria/overlays';
import cx from 'classnames';

import { Button } from '../../Button';
import { MENU_ITEMS, IRootMenuItem } from '../../Header';
import { CaretDownIcon } from '../../Icons';
import { Link } from '../../Link';
import { MenuDropdown } from '../../MenuDropdown';

const PublicMobileMenu = () => {
  usePreventScroll();

  const [selectedDropDown, setSelectedDropDown] = useState<IRootMenuItem | null>(null);

  const handleMenuPress = (item: IRootMenuItem) => {
    setSelectedDropDown(item.id === selectedDropDown?.id ? null : item);
  };

  return (
    <MenuDropdown className="relative flex flex-col h-screen overflow-y-auto top-0 xl:none">
      <div className="flex flex-1 flex-col pb-8">
        <nav className="py-8">
          <ul className="flex flex-col m-0">
            {MENU_ITEMS.map((item) => {
              const buttonClasses = 'block color-black font-header hover:bg-grey-50 pointer px-6 py-2 weight-700';

              const { href: itemHref, id, label, subMenuItems = [] } = item;

              const isActiveItem = id === selectedDropDown?.id;

              return (
                <li key={id} className="mb-0">
                  {subMenuItems.length ? (
                    <>
                      <Button
                        aria-expanded={isActiveItem}
                        className={cx('items-center', 'flex', 'w-full', buttonClasses)}
                        aria-haspopup
                        onPress={() => handleMenuPress(item)}
                      >
                        {label}
                        <div
                          className={cx(
                            'items-center',
                            'bg-grey-50',
                            'flex',
                            'justify-center',
                            'ml-auto',
                            'rounded-full',
                            'transition-200',
                            {
                              'rotate-180': isActiveItem,
                            },
                          )}
                        >
                          <CaretDownIcon />
                        </div>
                      </Button>
                      {isActiveItem && (
                        <ul className="bg-grey-100 flex flex-col pb-6 pt-10 px-6 m-0">
                          {subMenuItems.map(({ description, href: subMenuHref, id: subMenuId, label: subMenuLabel }) => (
                            <li key={subMenuId} className="flex flex-col mb-12">
                              <span className="font-header h6 mb-6 weight-800">{subMenuLabel}</span>
                              <Link className="color-accent font-header text-underline weight-700" href={subMenuHref}>
                                {description}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </>
                  ) : (
                    <Link className={buttonClasses} href={itemHref}>
                      {label}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </nav>

        <div className="items-center flex justify-center mb-8 mt-16">
          <Link className="mx-2" href="/login" theme="default">
            Partner hub
          </Link>
        </div>

        <div className="text-center mb-16">
          <span className="color-black">&copy; {new Date().getFullYear()} All rights reserved</span>
        </div>
      </div>
    </MenuDropdown>
  );
};

export { PublicMobileMenu };
