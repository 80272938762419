import { useState } from 'react';

import { useToggle } from '@netfront/common-library';
import cx from 'classnames';
import { useRouter } from 'next/router';

import { MENU_ITEMS } from '../Header.constants';
import { IRootMenuItem } from '../Header.interfaces';

import { Link, LogoLink, MenuDropdown, MobileMenuTrigger, PublicMobileMenu } from '../../../components';
import { pageConstants } from '../../../constants';

const PublicHeader = () => {
  const { CTLS_LOGO_SRC, RRR_LOGO_SIMPLE_SRC } = pageConstants;
  const { asPath } = useRouter();
  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState<boolean>(false);
  const [selectedDropDown, setSelectedDropDown] = useState<IRootMenuItem>();

  const { isToggled: isMobileMenuOpen, toggle } = useToggle();

  const handleMenuClose = () => {
    setIsDropDownMenuOpen(false);
    setSelectedDropDown(undefined);
  };

  return (
    <header className="index-2 relative shadow flex flex-col">
      <div className="bg-white border-b border-grey-100 index-2 py-2 relative">
        <div className="items-center container flex">
          <LogoLink logoSrc={CTLS_LOGO_SRC} size="large" />

          <MobileMenuTrigger isOpen={isMobileMenuOpen} onPress={toggle} />

          <div className="ml-auto none xl:flex">
            <nav>
              <ul className="items-center flex">
                {MENU_ITEMS.map((item) => {
                  const { href, id, label } = item;

                  const isActive = href === asPath;

                  const navLinkClasses = cx('flex items-center color-green-900 font-header pointer px py-8 ml-2 mb-0', {
                    'h-active-link': isActive,
                  });

                  return (
                    <li key={id} className="mb-0">
                      <Link className={navLinkClasses} href={href}>
                        {label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          <div className="ml-auto none xl:flex">
            <div aria-label="Login" className="flex items-center mr-4">
              <Link href="/login" theme="default">
                Partner hub
              </Link>
            </div>
            <Link href="/public-page/sustainability">
              <img alt="renew, reuse, recycle logo" className="w-26" src={RRR_LOGO_SIMPLE_SRC} />
            </Link>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && <PublicMobileMenu />}

      {isDropDownMenuOpen && selectedDropDown && (
        <MenuDropdown className="none xl:flex top-full" onOpenChange={handleMenuClose}>
          <div className="container-md py-12">
            <ul className="flex m-0">
              {selectedDropDown.subMenuItems?.map(({ description, href, id, label }) => (
                <li key={id} className="flex flex-col mr-24">
                  <span className="font-header weight-800 h6 mb-6">{label}</span>
                  <Link className="color-accent font-header text-underline weight-700" href={href}>
                    {description}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </MenuDropdown>
      )}
    </header>
  );
};

export { PublicHeader };
