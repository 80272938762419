import { useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { removeItemFromLocalStorage } from '@netfront/common-library';
import { useContentPageContext } from '@netfront/ekardo-content-library';
import {
  IResendActivationCodeOnCompletedResponse,
  LoginForm,
  useLogin,
  useLogout,
  useResendActivationCode,
} from '@netfront/gelada-identity-library';
import { Dialog } from '@netfront/ui-library';
import isEmpty from 'lodash.isempty';
import { useRouter } from 'next/router';

import { Button, BUTTON_CLASSES, ILoginCredentials, SingleFormPage } from '../../../components';
import { pageConstants } from '../../../constants';
import { useIsMounted, useToast } from '../../../hooks';

const LoginPage = () => {
  const { USER_NOT_ACTIVATED_MESSAGE } = pageConstants;
  const [loginCredentials, setLoginCredentials] = useState<ILoginCredentials>({} as ILoginCredentials);
  const [isResendActivationCodeDialogOpen, setIsResendActivationCodeDialogOpen] = useState<boolean>(false);

  const { dispatch } = useContentPageContext();
  const { push, prefetch, query } = useRouter();
  const { handleToastError, handleToastSuccess } = useToast();

  const { isMounted } = useIsMounted();

  const { requestedRoute } = query;

  const navigateToUrlAfterLoginCompleted = requestedRoute ? String(requestedRoute) : '/partner-hub';

  const handleLoginCompleted = () => {
    push(navigateToUrlAfterLoginCompleted).catch((error) =>
      handleToastError({
        shouldUseFriendlyErrorMessage: true,
        error,
      }),
    );
  };

  const handleLoginError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    const { message } = error;

    if (message === USER_NOT_ACTIVATED_MESSAGE) {
      setIsResendActivationCodeDialogOpen(true);
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleLogoutCompleted = () => {
    dispatch({
      type: 'unloadContentPage',
    });

    removeItemFromLocalStorage('userFlowIds');

    // eslint-disable-next-line no-console
    console.log('Automatic logout');
  };

  const { handleLogin, isLoading = false } = useLogin({
    onCompleted: handleLoginCompleted,
    onError: handleLoginError,
  });

  const { handleLogout } = useLogout({
    onCompleted: handleLogoutCompleted,
  });

  const handleResendActivationCodeCompleted = (data?: IResendActivationCodeOnCompletedResponse) => {
    if (!data) return;

    setIsResendActivationCodeDialogOpen(false);
    handleToastSuccess({
      message: 'Your activation code has been sent to your email',
    });
  };

  const { handleResendActivationCode, isLoading: isResendActivationCodeLoading = false } = useResendActivationCode({
    onCompleted: handleResendActivationCodeCompleted,
    onError: (error) =>
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      }),
  });

  const resendActivationCode = () => {
    if (isEmpty(loginCredentials)) return;
    const { email } = loginCredentials;

    void handleResendActivationCode({
      email,
    });
  };

  useEffect(() => {
    prefetch(navigateToUrlAfterLoginCompleted).catch((error) =>
      handleToastError({
        shouldUseFriendlyErrorMessage: true,
        error,
      }),
    );

    handleLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SingleFormPage
      className="c-login-page"
      isPreloaderVisible={isLoading || isResendActivationCodeLoading}
      meta={{ seoDescription: 'Access your account by entering your details below', seoTitle: 'Login' }}
      title="Login"
    >
      <div className="c-login-form py-12 px-24">
        <h1 className="h1 color-red-700 mb-2">Login</h1>
        <p className="mb-4 font-size-span">Access your account by entering your details below</p>

        <LoginForm
          buttonClassName={BUTTON_CLASSES.default}
          forgotPasswordText="Forgot password?"
          forgotPasswordUrl="/forgot-password"
          isSubmitting={isLoading}
          onLogin={(login, password) => {
            setLoginCredentials({
              email: login,
              password,
            });

            void handleLogin({ login, password });
          }}
          isTogglePasswordVisible
        />
      </div>

      {isMounted && (
        <Dialog
          isOpen={isResendActivationCodeDialogOpen}
          title="Activate your account"
          onClose={() => setIsResendActivationCodeDialogOpen(false)}
        >
          <p className="mb-8">Your email hasn&#39;t been activated, click below to resend activation</p>

          <Button theme="default" onPress={() => resendActivationCode()}>
            Resend activation code
          </Button>
        </Dialog>
      )}
    </SingleFormPage>
  );
};

export { LoginPage };
