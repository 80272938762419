const BLOG_GROUP_ID = 837;
const PARTNER_HUB_PAGE_TITLE = 'Partner hub';
const PARTNER_HUB_SEO_DESCRIPTION = 'View our partners';
const PARTNER_HUB_SEO_TITLE = 'Partner hub for viewing partners';

const partnerHubPageConstants = Object.freeze({
  BLOG_GROUP_ID: BLOG_GROUP_ID,
  PARTNER_HUB_PAGE_TITLE: PARTNER_HUB_PAGE_TITLE,
  PARTNER_HUB_SEO_DESCRIPTION: PARTNER_HUB_SEO_DESCRIPTION,
  PARTNER_HUB_SEO_TITLE: PARTNER_HUB_SEO_TITLE,
});

export { partnerHubPageConstants };
