import { useState, useEffect } from 'react';

import { IUsePasswordValidation, IUsePasswordValidationParams } from './usePasswordValidation.interfaces';

export const usePasswordValidation = ({
  firstPassword = '',
  secondPassword = '',
  minimumLength = 8,
}: IUsePasswordValidationParams): IUsePasswordValidation => {
  const [hasMinimumLength, setHasMinimumLength] = useState<boolean>(false);
  const [hasNumber, setHasNumber] = useState<boolean>(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState<boolean>(false);
  const [hasUpperAndLowerCase, setHasUpperAndLowerCase] = useState<boolean>(false);
  const [isMatch, isSetMatch] = useState<boolean>(false);

  useEffect(() => {
    setHasMinimumLength(firstPassword.length > 7);
    setHasNumber(RegExp(/([0-9])/, 'g').exec(firstPassword) !== null);
    setHasSpecialCharacter(RegExp(/([!,%,&,@,#,$,^,*,?,_,~])/, 'g').exec(firstPassword) !== null);
    setHasUpperAndLowerCase(RegExp(/([a-z].*[A-Z])|([A-Z].*[a-z])/, 'g').exec(firstPassword) !== null);
    isSetMatch(firstPassword ? firstPassword === secondPassword : false);
  }, [firstPassword, secondPassword, minimumLength]);

  return { hasMinimumLength, hasNumber, hasSpecialCharacter, hasUpperAndLowerCase, isMatch };
};
