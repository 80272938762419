import React from 'react';

import { SingleFormPageProps } from './SingleFormPage.interfaces';
import styles from './SingleFormPage.module.css';

import { Page } from '../../../components';

const SingleFormPage = ({ children, shouldOverrideContainer = false, ...rest }: SingleFormPageProps) => {
  const containerStyles = shouldOverrideContainer ? undefined : 'container container-xs';

  return (
    <Page {...rest}>
      <section className={`${styles['c-single-form-page']} `}>
        <div className={containerStyles}>{children}</div>
      </section>
    </Page>
  );
};

export { SingleFormPage };
