import { gql } from '@apollo/client';

const SEND_CONTACT_FORM_MUTATION = gql`
  mutation sendContactForm(
    $email: String!
    $firstName: String!
    $lastName: String!
    $message: String!
    $phoneNumber: String
    $projectId: String!
    $templateId: Int
  ) {
    notification {
      sendContactForm(
        firstname: $firstName
        lastname: $lastName
        message: $message
        phoneNumber: $phoneNumber
        projectId: $projectId
        sender: $email
        templateId: $templateId
      )
    }
  }
`;

export { SEND_CONTACT_FORM_MUTATION };
