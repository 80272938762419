import React, { useEffect } from 'react';

import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { useAuthentication } from '@netfront/gelada-identity-library';
import { useRouter } from 'next/router';

import styles from '../PartnerHubPage/PartnerHubPage.module.css';
import { SingleFormPage } from '../SingleFormPage';

import { inviteAColleaguePageConstants } from './InviteAColleaguePage.constants';

import { useSendContactForm, ISendContactFormOnCompletedResponse, useToast } from '../../../hooks';
import { ContactForm } from '../../Forms';

const InviteAColleaguePage = () => {
  const { isAuthenticated } = useAuthentication();

  const { INVITE_COLLEAGUE_TEMPLATE_ID, INVITE_COLLEAGUE_SEO_DESCRIPTION, INVITE_COLLEAGUE_SEO_TITLE, INVITE_COLLEAGUE_PAGE_TITLE } =
    inviteAColleaguePageConstants;

  const { push } = useRouter();
  const { handleToastError } = useToast();

  const handleSendContactFormCompleted = (data?: ISendContactFormOnCompletedResponse) => {
    if (!data) {
      return;
    }

    const { isCompleted } = data;

    if (isCompleted) {
      push(`/`).catch((error) => {
        handleToastError({
          error,
        });
      });

      return;
    }

    const {
      messages: {
        error: { unexpected },
      },
    } = COMMON_LIBRARY_CONSTANTS;

    handleToastError({
      error: new Error(unexpected),
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleSendContactFormError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const { handleSendContactForm, isLoading = false } = useSendContactForm({
    onCompleted: handleSendContactFormCompleted,
    onError: handleSendContactFormError,
  });

  useEffect(() => {
    if (!isAuthenticated) {
      push('/login').catch((error) =>
        handleToastError({
          error,
          shouldUseFriendlyErrorMessage: true,
        }),
      );

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <SingleFormPage
      isPreloaderVisible={isLoading}
      meta={{ seoDescription: INVITE_COLLEAGUE_SEO_DESCRIPTION, seoTitle: INVITE_COLLEAGUE_SEO_TITLE }}
      title={INVITE_COLLEAGUE_PAGE_TITLE}
    >
      <div className="py-40">
        <h1 className="h1 color-red-700 mb-2">Invite a colleague</h1>
        <span className="mb-10 font-size-p">Invite a colleague to the CTLS team</span>

        <ContactForm
          additionalSubmitButtonClassNames={styles['c-submit-button']}
          isSubmitting={isLoading}
          onSend={({ email, firstName, lastName, message }) =>
            handleSendContactForm({
              email,
              firstName,
              lastName,
              message,
              projectId: 'ctls',
              templateId: INVITE_COLLEAGUE_TEMPLATE_ID,
            })
          }
        />
      </div>
    </SingleFormPage>
  );
};

export { InviteAColleaguePage };
