import React from 'react';

import { HomeIcon } from '../Icons';

import { BreadcrumbItemLink } from './BreadcrumbItemLink';

const BreadcrumbItemHomePageLink = () => {
  return (
    <BreadcrumbItemLink
      content={
        <>
          <span className="sr-only">Go to homepage</span>
          <HomeIcon />
        </>
      }
      href="/"
    />
  );
};

export { BreadcrumbItemHomePageLink };
