import { Link } from '../../../components';
import { pageConstants } from '../../../constants';

const PublicFooter = () => {
  const { CTLS_HOME_PAGE_URL, CTLS_LOGO_SRC, NETFRONT_HOME_PAGE_URL } = pageConstants;

  return (
    <footer className="relative mt-auto">
      <div className="bg-white py-12">
        <div className="container flex flex-col justify-center md:flex-row">
          <div className="w-full md:w-1/2">
            <div className="flex justify-center flex-col items-center">
              <Link href={CTLS_HOME_PAGE_URL} target="_blank">
                <span className="sr-only">Go to Commercial & Technical Lighting Solutions website</span>
                <img alt="Commercial & Technical Lighting Solution" className="max-w-34 mb-4" src={CTLS_LOGO_SRC} />
              </Link>
              <span className="mt-4 flex size-3">
                &copy; {new Date().getFullYear()} CTLS. All rights reserved, Built by
                <Link className="color-black-900 block ml-1 size-3 text-underline" href={NETFRONT_HOME_PAGE_URL} target="_blank">
                  Netfront
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export { PublicFooter };
