import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { SEND_CONTACT_FORM_MUTATION } from './useSendContactForm.graphql';
import {
  IHandleSendContactFormParams,
  ISendContactFormMutationGraphQLResponse,
  ISendContactFormMutationVariables,
  IUseSendContactFormOptions,
  IUseSendContactForm,
} from './useSendContactForm.interfaces';

const useSendContactForm = (options?: IUseSendContactFormOptions): IUseSendContactForm => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSendContactFormOptions);

  const [executeSendContactForm, { loading: isLoading }] = useHowlerMutation<
    ISendContactFormMutationGraphQLResponse,
    ISendContactFormMutationVariables
  >({
    mutation: mutation ?? SEND_CONTACT_FORM_MUTATION,
    options: {
      onCompleted: (data) => {
        if (!onCompleted) {
          return;
        }

        const {
          notification: { sendContactForm: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleSendContactForm = async ({
    email,
    firstName,
    lastName,
    message,
    phoneNumber,
    projectId,
    templateId,
  }: IHandleSendContactFormParams) => {
    await executeSendContactForm({
      variables: {
        email,
        firstName,
        lastName,
        message,
        phoneNumber,
        projectId,
        templateId,
      },
    });
  };

  return {
    handleSendContactForm,
    isLoading,
  };
};

export { useSendContactForm };
