import { Link } from '../Link';

import { logoLinkConstants } from './LogoLink.constants';
import { LogoLinkProps } from './LogoLink.interfaces';

const LogoLink = ({ href = '/', logoSrc, size = 'medium' }: LogoLinkProps) => {
  const { LOGO_SIZES } = logoLinkConstants;

  return (
    <Link className="inline-block mr-8" href={href}>
      <span className="sr-only">Go to homepage</span>
      <img alt="Go to homepage" className={`block max-w-${LOGO_SIZES[size]}`} loading="lazy" src={logoSrc} />
    </Link>
  );
};

export { LogoLink };
