import { removeItemFromLocalStorage, useToggle } from '@netfront/common-library';
import { useLogout } from '@netfront/gelada-identity-library';
import cx from 'classnames';
import { useRouter } from 'next/router';

import { Button, Link, LogoLink, MenuDropdown, MobileMenuTrigger, PrivateMobileMenu, MENU_ITEMS, UserInitials } from '../../../components';
import { pageConstants } from '../../../constants';
import { useIsMounted, useToast } from '../../../hooks';

const PrivateHeader = () => {
  const { CTLS_LOGO_SRC, RRR_LOGO_SIMPLE_SRC } = pageConstants;
  const { asPath } = useRouter();

  const { push } = useRouter();
  const { handleToastError } = useToast();

  const { isToggled: isMobileMenuOpen, toggle: toggleMobileMenu } = useToggle();
  const { isToggled: isUserMenuOpen, toggle: toggleUserMenu } = useToggle();
  const { isMounted } = useIsMounted();

  const handleLogoutCompleted = () => {
    removeItemFromLocalStorage('userFlowIds');

    push('/').catch((error) =>
      handleToastError({
        shouldUseFriendlyErrorMessage: true,
        error,
      }),
    );
  };

  const { handleLogout } = useLogout({
    onCompleted: handleLogoutCompleted,
  });

  return (
    <header className="index-2 relative shadow-lg no-print">
      <div className="bg-white index-2 py-4 relative xl:py-0">
        <div className="items-center container-xl flex">
          <LogoLink logoSrc={CTLS_LOGO_SRC} size="large" />

          <MobileMenuTrigger isOpen={isMobileMenuOpen} onPress={toggleMobileMenu} />

          <div className="ml-auto none xl:flex">
            <nav>
              <ul className="items-center flex">
                {MENU_ITEMS.map((item) => {
                  const { href, id, label } = item;
                  const isActive = href === asPath;

                  const navLinkClasses = cx('flex items-center color-green-900 font-header pointer px py-8 ml-2 mb-0', {
                    'h-active-link': isActive,
                  });

                  return (
                    <li key={id} className="mb-0">
                      <Link className={navLinkClasses} href={href}>
                        {label}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          <div className="ml-auto none xl:flex xl:items-center">
            {isMounted && (
              <>
                <div aria-label="Login and registation" className="flex items-center mr-4">
                  <Link href="/invite-a-colleague" theme="redOutline">
                    Invite a colleague
                  </Link>
                </div>
                <div aria-label="Login" className="flex items-center mr-4">
                  <Link href="/partner-hub" theme="default">
                    Partner hub
                  </Link>
                </div>
              </>
            )}
            <div className="relative">
              <Button aria-expanded={isUserMenuOpen} className="none xl:flex pointer p-0 mr-4" aria-haspopup onPress={toggleUserMenu}>
                <UserInitials />
              </Button>
              {isUserMenuOpen && (
                <MenuDropdown
                  className="absolute bg-white border border-grey-100 overflow-hidden radius right-1 my-1 shadow-lg w-auto"
                  onOpenChange={toggleUserMenu}
                >
                  <ul className="ml-0">
                    <li className="ml-0 mb-0">
                      <Link className="block color-primary hover:bg-grey-50 pointer p-4 px-8" href="/user/profile">
                        Profile
                      </Link>
                    </li>
                    <li className="ml-0 mb-0">
                      <Button className="color-primary hover:bg-grey-50 pointer p-4 px-8" onPress={handleLogout}>
                        Logout
                      </Button>
                    </li>
                  </ul>
                </MenuDropdown>
              )}
            </div>
            <Link href="/public-page/sustainability">
              <img alt="renew, reuse, recycle logo" className="w-26" src={RRR_LOGO_SIMPLE_SRC} />
            </Link>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && <PrivateMobileMenu />}
    </header>
  );
};

export { PrivateHeader };
